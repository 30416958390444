<template>
  <div>
    <!-- <div class="input-inner">
      <input class="input" v-model="code" type="text" :placeholder="$t('请输入神秘代码')">
      <el-button class="input-btn button_blue" @click="submit" :loading="btnLoading">{{ $t('兑换') }}</el-button>
    </div> -->
    <div class="title">
      <span>{{ $t("奖励活动") }}</span>
    </div>
    <none v-if="bonusList.length===0" v-loading="loading"></none>
    <div class="row" v-else>
      <div class="bonus-item" v-for="item, index in bonusList" :key="index">
        <div class="new" v-if="$store.state.msg.noMsg[item.type]"></div>
        <div class="bonus-item-content">
          <div class="bonus-item-desc-warp">
            <div class="item-title">{{ item.title }}</div>
            <div class="item-desc">{{ item.content }}</div>
          </div>
          <img :src="item.cover" class="bonus-item-cover" :class="{'bonus-action-item-cover':item.type==='action'}">
        </div>

        <!-- 周礼金显示 -->
        <template  v-if="item.url_type === 'api' && item.type==='vip_week' && item.extra_data">
          <el-button class="button_blue item-btn" :disabled="!(item.is_receive===0 && item.status===1)" v-if="item.extra_data.week_is_receive_vip_reward==0" @click="receive(item)" :loading="item.loading">{{ $t("领取") }}</el-button>
          <el-button class="button_blue bth-black item-btn" v-else>{{ formattedTime(item.extra_data.week_last_receive_time,'周') }}</el-button>
        </template>

        <!-- 月礼金显示 -->
        <template  v-else-if="item.url_type === 'api' && item.type==='vip_month' && item.extra_data">
          <el-button class="button_blue item-btn" :disabled="!(item.is_receive===0 && item.status===1)" v-if="item.extra_data.month_is_receive_vip_reward==0" @click="receive(item)" :loading="item.loading">{{ $t("领取") }}</el-button>
          <el-button class="button_blue bth-black item-btn" v-else>{{ formattedTime(item.extra_data.month_last_receive_time,'月') }}</el-button>
        </template>
        
        <!-- 其他显示 -->
        <el-button class="button_blue item-btn" v-else-if="item.url_type === 'api'" :disabled="!(item.is_receive===0 && item.status===1)" @click="receive(item)" :loading="item.loading">
          <span v-if="item.is_receive === 0">{{ $t("领取") }}</span>
          <span v-else-if="item.is_receive === 1">{{ $t("已领取") }}</span>
        </el-button>
        <el-button class="button_blue item-btn bth-black" v-else-if="item.type==='download'" @click="downApp">{{ $t("下载") }}</el-button>

        <el-button class="button_blue item-btn bth-black" v-else @click="$router.push(item.url)">{{ $t("详情") }}</el-button>
      </div>
    </div>

    <edemptionCodePop ref="edemptionCodePop"></edemptionCodePop>
    <edemptionCodeSuccess ref="edemptionCodeSuccess"></edemptionCodeSuccess>
  </div>
</template>

<script>
import { code_receive } from '@/api/code'
import { bonus_list_api } from '@/api/bonus'
import edemptionCodePop from './edemptionCodePop.vue'
import edemptionCodeSuccess from './edemptionCodeSuccess.vue'
import request from '@/utils/request'
export default {
  components: { edemptionCodePop, edemptionCodeSuccess },
  data() {
    return {
      code: '',
      btnLoading: false,
      loading:false,
      bonusList:[],
      curTime:new Date().getTime(),
      timeId:null,
      typeImageList:{
        invite:require('@/assets/images/invite.png'),
        vip_week:require('@/assets/images/vip_week.png'),
        vip_month:require('@/assets/images/vip_month.png'),
        task:require('@/assets/images/task.png'),
        task_stage:require('@/assets/images/task_stage.png'),
        action:require('@/assets/images/action.png'),
        sign:require('@/assets/images/sign.png'),
        pdd:require('@/assets/images/pdd.png'),
        download:require('@/assets/images/downloadApp-cover.png')
      }
    }
  },
  mounted() {
    this.getBonusList()
    this.timeId = setInterval(()=>{
      this.curTime = new Date().getTime()
    },1000)
  },
  beforeDestroy(){
    this.timeId && clearInterval(this.timeId);
  },
  methods: {
    formattedTime(time, type) {
      let nextTime;
      time = time * 1000;
      if (type === "周") {
        nextTime = time + 7 * 24 * 60 * 60 * 1000;
      } else {
        nextTime = time + 30 * 24 * 60 * 60 * 1000;
      }
      let rTime = (nextTime - this.curTime) / 1000;

      // 判断时间时候为负数，负数改变状态
      if (rTime <= 0) rTime=0;

      const days = Math.floor(rTime / (24 * 3600));
      const hours = Math.floor((rTime % (24 * 3600)) / 3600);
      const minutes = Math.floor((rTime % 3600) / 60);
      const seconds = Math.floor(rTime % 60);

      const formattedDays = String(days).padStart(1, "0");
      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      return `${formattedDays}d ${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
    },

    async getBonusList() {
      this.loading = true
      const { data: res } = await bonus_list_api();
      this.loading = false
      if (res.code == 1) {
        res.data.unshift({
          url:"/download",
          type:"download",
          title:this.$t("下载app"),
          content:this.$t("下载app简介"),
        })
        this.bonusList = res.data.map(item=>({
          ...item,
          cover:item.type==="action" ? item.image || this.typeImageList[item.type] : this.typeImageList[item.type]
        }))
        console.log(this.bonusList)

      }
    },
    async receive(item){
      item.loading = true
      this.$forceUpdate()
      const {data:res} = await request({
        url: item.url,
        method: item.method,
        data:item.params
      })
      if(res.code===1){
				this.$store.commit("msg/reduce", item.type)
        await this.getBonusList();
        this.$message({
          customClass: "successMessage",
          showClose: false,
          message: res.msg,
          center: true,
          type: "success",
        });
      }else{
        this.$message.error(res.msg);
      }
      item.loading = false
      this.$forceUpdate()
    },
    showPop() {
      if (!this.$store.state.isLoginStatu) {
        this.showLoginReg(0);
        return;
      }
    },
    async submit() {
      if (!this.$store.state.isLoginStatu) {
        this.showLoginReg(0);
        return;
      }
      if (!this.code) {
        this.$message.error(this.$t("请输入神秘代码"));
        return
      }
      this.btnLoading = true
      const { data } = await code_receive({ code: this.code })
      this.btnLoading = false
      if (data.code == 1) {
        this.$refs.edemptionCodeSuccess.show(data.data.amount)
        this.code = ''
      } else {
        this.$message.error(data.msg);
      }
    }
  }
}
</script>

<style scoped lang="less" src="@/assets/css/edemptionCode.less"></style>